
import { Vue, Component } from "vue-property-decorator";
import BSection from "@/components/bs/BSection.vue";

@Component({
  components: { BSection },
})
export default class Hero extends Vue {
  get hasSlot(): boolean {
    return !this.$_.isEmpty(this.$slots);
  }
}
